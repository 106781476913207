body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.folder {
  display: inline-flex;
  flex-direction: column;
  border-color:black;
  border-width:1px;
  border-style:solid;
  padding: 4px;
  border-radius: 5px;
  margin:5px;
}

.folder > .caption {
  overflow:hidden;
  text-align: center;
  text-overflow: ellipsis; 
  max-lines:2;
  background-color: white;
  flex-grow: 0;
}

.folder > .image {
  margin:1px;
  flex-grow: 1;
  object-fit: contain;
  align-self: center;;
}

.folder.icon > .image {
  width:32px;
  height:32px;
  
}

.folder:hover {
  background-color: darkgray;
}

.picture:hover {
  background-color: darkgray;
}

.folder.selected {
  background-color: blue;
}

.folder input {
  width: 50px;
}

.directoryList {
  width:100%;
  overflow-y:scroll;
  overflow-x:hidden;
  float:left;
  gap: 5px; 
}

.directoryList.small > .folder.thumb > .caption {
  display:none;
}
.directoryList.medium > .folder.thumb > .caption {
  display:none;
}


.itemDetails {
  position:fixed;
  bottom:10px;
  left:10px;
  right:10px;
  border-radius: 4px;
  border-width: 2px;
  border-style:solid;
  background-color: white;
  display:flex;
  flex-direction: column;
}

.itemDetails {
  background-color: white;
}

.itemDetails .titlebar {
  background-color: lightblue;
  display:flex;
}
.itemDetails .titlebar .title {
  flex-grow:1;
}

.itemDetails .titlebar .button {
  display:float;
  align-self:center;
  margin:2px;
}

.itemDetails .properties{
  display:flex;
}

.itemDetails .properties .caption {
  flex:1;
}

.itemDetails .properties.changed .caption {
  font-weight: bold;
}

.itemDetails .properties.unchanged .submitButton {
  display:none;
}
